import type { QueryStatus } from 'react-query';
import type { IsAuthenticateResponse } from '@/model/is-authenticate/types';

/**
 * @function normalize
 * @param status
 * @param data
 */
const normalize = (status: QueryStatus, data?: IsAuthenticateResponse) => {
  if (status !== 'success' || !data || !data.data) {
    return false;
  }
  return data.data.is_authenticate ?? false;
};

export default normalize;
