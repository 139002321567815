import '@polyfills/object-entries';
import '@polyfills/requestIdleCallback';
import '@polyfills/String.prototype.endsWith';
import '@polyfills/String.prototype.startsWith';
import '@polyfills/String.prototype.trimStart';
import globalStyle from '@/styles/global';

import type { NextComponentType } from 'next';
import type { AppPropsWithLayout } from '@/model/types';

import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import { ErrorBoundary } from 'react-error-boundary';
import { Global } from '@emotion/react';

import AppProvider from '@/context';
import BaseTag from '@/components/SEO/BaseTag';
import BoundaryError from '@/components/Error/Boundary';
import Layout from '@/layouts';
import Notification from '@/components/Notification';

/**
 * @function App
 * @param props
 */
const App: NextComponentType<any, any, AppPropsWithLayout> = props => {
  const { Component, pageProps = {} } = props;
  const [client] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={client}>
      <Hydrate state={pageProps.dehydratedState}>
        <BaseTag />
        <ErrorBoundary FallbackComponent={BoundaryError}>
          <AppProvider>
            <Layout layout={Component.getLayout}>
              <Component {...pageProps} />
            </Layout>
            <Global styles={globalStyle} />
            <Notification />
          </AppProvider>
        </ErrorBoundary>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default App;
