import type { VFC } from 'react';
import Head from 'next/head';

/**
 * @function BaseTag
 */
const BaseTag: VFC<unknown> = () => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=no, width=device-width"
      />
      <link rel="icon" href="/favicon.ico" />
      <title key="title">BestHome Intools</title>
      <meta key="description" name="description" content="BestHome internal management tools" />
    </Head>
  );
};

export default BaseTag;
