import type { FC } from 'react';
import { createContext, useMemo, useEffect } from 'react';

import type { AuthenticationProviderProps, AuthenticationContextType } from '@/context/authentication/model/types';
import useAuthentication from '@/context/authentication/repository/is-authentication';
import useLogout from '@/context/authentication/repository/logout';
import useLogoutEvent from '@/context/authentication/usecase/use-logout-event';
import useEmitter from '@/context/authentication/hooks/use-emitter';

/**
 * @constant AuthenticationContext
 */
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined);

/**
 * @function AuthenticationProvider
 * @param props
 */
const AuthenticationProvider: FC<AuthenticationProviderProps> = props => {
  const { children } = props;
  const events = useEmitter();

  const { isAuth, loading } = useAuthentication();
  const { onSuccess, onError } = useLogoutEvent({
    emitter: events,
  });
  const { onLogout } = useLogout({
    onSuccess: onSuccess,
    onError: onError,
  });

  useEffect(() => {
    events.on('@authentication/logout', onLogout);
    return () => {
      events.off('@authentication/logout', onLogout);
    };
  }, [events, onLogout]);

  const context = useMemo<AuthenticationContextType>(() => {
    return {
      events: events,
      isAuth: isAuth,
      loading: loading,
    };
  }, [events, isAuth, loading]);

  return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationProvider;
