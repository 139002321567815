import type { FC } from 'react';
import { createContext } from 'react';

import { useAuth } from '@/context/authentication';

import type { ProfileContextType, ProfileProviderProps } from './model/types';

import useInternalUserInfo from './repository/internal-user-info';

/**
 * @constant ProfileContext
 */
export const ProfileContext = createContext<ProfileContextType>(undefined);

/**
 * @function ProfileProvider
 * @param props
 */
const ProfileProvider: FC<ProfileProviderProps> = props => {
  const { children } = props;
  const { loading, isAuth } = useAuth();
  const context = useInternalUserInfo({
    skip: loading || !isAuth,
  });

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
