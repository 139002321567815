import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import { STATUS_CODES } from '../constant';
import { containerCx, bodyCx, titleCx, descriptionCx, textCx } from '../styles';

interface Props extends FallbackProps {
  statusCode?: number;
  title?: string;
}

/**
 * @function BoundaryError
 * @param {Props} props
 */
const BoundaryError: React.VFC<Props> = props => {
  const { statusCode = 500, title = '' } = props;
  const _title = title || STATUS_CODES[statusCode] || 'An unexpected error has occurred';

  return (
    <div className={containerCx}>
      <div className={bodyCx}>
        <h1 className={titleCx}>{statusCode}</h1>
        <div className={descriptionCx}>
          <h2 className={textCx}>{_title}.</h2>
        </div>
      </div>
    </div>
  );
};

export default BoundaryError;
