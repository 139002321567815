import { useContext } from 'react';

import type { ProfileContextType } from '../model/types';
import { ProfileContext } from '../profile';

/**
 * @function useProfile
 */
const useProfile = () => {
  const context = useContext<ProfileContextType>(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export default useProfile;
