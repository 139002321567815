///////////////////////////////////////////////////////////////////////////////
// GREEN
///////////////////////////////////////////////////////////////////////////////

export const G50 = '#EDFCF8';
export const G100 = '#C2F4E9';
export const G200 = '#97ECDA';
export const G300 = '#6CE5CB';
export const G400 = '#26D9B2';
export const G500 = '#1EAE8F';
export const G600 = '#188B72';
export const G700 = '#115F4E';
export const G800 = '#0B4135';
export const G900 = '#0A1F12';

///////////////////////////////////////////////////////////////////////////////
// TEAL
///////////////////////////////////////////////////////////////////////////////

export const T50 = '#EDFCFC';
export const T100 = '#C1F3F6';
export const T200 = '#95EBEF';
export const T300 = '#68E3E9';
export const T400 = '#21D6DE';
export const T500 = '#1AA9B2';
export const T600 = '#038B91';
export const T700 = '#106266';
export const T800 = '#0A4143';
export const T900 = '#051E1F';

///////////////////////////////////////////////////////////////////////////////
// BLUE
///////////////////////////////////////////////////////////////////////////////

export const B50 = '#EBFFFE';
export const B100 = '#C2FCFF';
export const B200 = '#89EDFB';
export const B300 = '#5EDDF3';
export const B400 = '#33C5FF';
export const B500 = '#009AD6';
export const B600 = '#047CBE';
export const B700 = '#055A8E';
export const B800 = '#0F3A57';
export const B900 = '#081D2B';

///////////////////////////////////////////////////////////////////////////////
// PURPLE
///////////////////////////////////////////////////////////////////////////////

export const P50 = '#F9F7FD';
export const P100 = '#E8DEF8';
export const P200 = '#B99BE9';
export const P300 = '#A57DE3';
export const P400 = '#8B58DA';
export const P500 = '#7132D2';
export const P600 = '#5825A7';
export const P700 = '#421C7D';
export const P800 = '#2C1254';
export const P900 = '#16092A';

///////////////////////////////////////////////////////////////////////////////
// RED
///////////////////////////////////////////////////////////////////////////////

export const R50 = '#FEF6F7';
export const R100 = '#F9D2D6';
export const R200 = '#F0939E';
export const R300 = '#EB6F7E';
export const R400 = '#E64759';
export const R500 = '#D52035';
export const R600 = '#A61727';
export const R700 = '#7A101C';
export const R800 = '#480A11';
export const R900 = '#2D060B';

///////////////////////////////////////////////////////////////////////////////
// YELLOW
///////////////////////////////////////////////////////////////////////////////

export const Y50 = '#FFFBF5';
export const Y100 = '#FFECCC';
export const Y200 = '#FFD285';
export const Y300 = '#FFBF52';
export const Y400 = '#FFA50A';
export const Y500 = '#FF8D0A';
export const Y600 = '#CC6D00';
export const Y700 = '#995200';
export const Y800 = '#522C00';
export const Y900 = '#331B00';

///////////////////////////////////////////////////////////////////////////////
// NEUTRAL
///////////////////////////////////////////////////////////////////////////////

export const N0 = '#FFFFFF';
export const N50 = '#F3F4F7';
export const N100 = '#ECF0F3';
export const N200 = '#D7DCE5';
export const N300 = '#BBC4D3';
export const N400 = '#95A3BB';
export const N500 = '#7C8DAB';
export const N600 = '#586988';
export const N700 = '#44526A';
export const N800 = '#28303E';
export const N900 = '#14191F';
export const N1000 = '#000000';

///////////////////////////////////////////////////////////////////////////////
// Object default export
///////////////////////////////////////////////////////////////////////////////

const token = {
  G50,
  G100,
  G200,
  G300,
  G400,
  G500,
  G600,
  G700,
  G800,
  G900,
  T50,
  T100,
  T200,
  T300,
  T400,
  T500,
  T600,
  T700,
  T800,
  T900,
  B50,
  B100,
  B200,
  B300,
  B400,
  B500,
  B600,
  B700,
  B800,
  B900,
  P50,
  P100,
  P200,
  P300,
  P400,
  P500,
  P600,
  P700,
  P800,
  P900,
  R50,
  R100,
  R200,
  R300,
  R400,
  R500,
  R600,
  R700,
  R800,
  R900,
  Y50,
  Y100,
  Y200,
  Y300,
  Y400,
  Y500,
  Y600,
  Y700,
  Y800,
  Y900,
  N0,
  N50,
  N100,
  N200,
  N300,
  N400,
  N500,
  N600,
  N700,
  N800,
  N900,
  N1000,
};

export default token;
