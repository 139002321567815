import type { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { AuthenticationProvider } from '@/context/authentication';
import { NotificationProvider } from '@/context/notification';
import { ProfileProvider } from '@/context/profile';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

import theme from '@/styles/material/theme';

interface Props {
  children: ReactNode;
}

/**
 * @function AppProvider
 * @param props
 */
const AppProvider: FC<Props> = props => {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <AuthenticationProvider>
          <ProfileProvider>
            <NotificationProvider>{children}</NotificationProvider>
          </ProfileProvider>
        </AuthenticationProvider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default AppProvider;
