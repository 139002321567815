import { useQuery } from 'react-query';
import { useMemo } from 'react';

import fetcher from '@/utils/react-query-utilities/fetcher';
import { ENDPOINTS } from '@/constants/endpoints';

import type { BaseResponse } from '@/model/types';
import type { InternalUserInfoResponse } from '@/model/internal-user-info/types';
import type { ProfileState } from '@/context/profile/model/types';
import type { APIError } from '@/utils/react-query-utilities/error';

import normalize from './normalize';

interface Dependencies {
  skip?: boolean;
}

/**
 * @function useInternalUserInfo
 * @param params
 */
const useInternalUserInfo = (params: Dependencies): ProfileState => {
  const { skip = false } = params;
  const { status, data } = useQuery<any, APIError<BaseResponse>, InternalUserInfoResponse, string[]>({
    queryKey: [ENDPOINTS.GET_INTERNAL_USER_INFO],
    queryFn: fetcher,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['status', 'data'],
    enabled: !skip,
  });

  return useMemo(() => {
    const state = normalize(status, data);
    return state;
  }, [data, status]);
};

export default useInternalUserInfo;
