import { useQuery } from 'react-query';

import fetcher from '@/utils/react-query-utilities/fetcher';
import { ENDPOINTS } from '@/constants/endpoints';

import type { BaseResponse } from '@/model/types';
import type { IsAuthenticateResponse } from '@/model/is-authenticate/types';
import type { AuthenticationState } from '@/context/authentication/model/types';
import type { APIError } from '@/utils/react-query-utilities/error';

import normalize from './normalize';

/**
 * @function useAuthentication
 */
const useAuthentication = (): AuthenticationState => {
  const { status, data } = useQuery<any, APIError<BaseResponse>, IsAuthenticateResponse, string[]>({
    queryKey: [ENDPOINTS.IS_AUTHENTICATE],
    queryFn: fetcher,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['status', 'data'],
  });

  const isAuth = normalize(status, data);
  return {
    loading: status === 'loading',
    isAuth: isAuth,
  };
};

export default useAuthentication;
