import type { QueryStatus } from 'react-query';
import type { InternalUserInfoResponse } from '@/model/internal-user-info/types';
import type { ProfileState } from '@/context/profile/model/types';

/**
 * @function normalize
 * @param status
 * @param data
 */
const normalize = (status: QueryStatus, data?: InternalUserInfoResponse): ProfileState => {
  const state: ProfileState = {
    name: '',
    email: '',
    phone: '',
    userID: -1,
    companyID: -1,
    loading: true,
    isInvestor: 0,
  };

  if (status !== 'success' || !data || !data.data) {
    return state;
  }

  state.name = data.data.name ?? '';
  state.email = data.data.email ?? '';
  state.phone = data.data.phone ?? '';
  state.userID = data.data.id ?? -1;
  state.companyID = data.data.company_id ?? -1;
  state.loading = false;
  state.isInvestor = data.data.is_investor ?? 0;

  return state;
};

export default normalize;
