import { createTheme } from '@mui/material/styles';
import { G500, G600, G700, Y500, Y700, R500, R700, B500, B700, N0 } from '@/styles/color';

const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createTheme({
  palette: {
    primary: {
      main: G500,
      light: G500,
      dark: G700,
      contrastText: N0,
    },
    secondary: {
      main: G500,
      light: G500,
      dark: G700,
      contrastText: N0,
    },
    success: {
      main: G500,
      light: G500,
      dark: G600,
      contrastText: N0,
    },
    warning: {
      main: Y500,
      light: Y500,
      dark: Y700,
      contrastText: N0,
    },
    error: {
      main: R500,
      light: R500,
      dark: R700,
      contrastText: N0,
    },
    info: {
      main: B500,
      light: B500,
      dark: B700,
      contrastText: N0,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display1: 'h1',
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          body1: 'div',
          body2: 'div',
          body3: 'div',
          caption: 'span',
          'caption-bold': 'span',
        },
      },
    },
  },
  typography: {
    fontFamily: fontFamily,
    splash: {
      fontSize: 28,
      fontWeight: 900,
      lineHeight: '27px',
    },
    display1: {
      fontSize: '64px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '72px',
      letterSpacing: 0,
    },
    h1: {
      fontSize: '24px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '32px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '40px',
        lineHeight: '52px',
        letterSpacing: 0,
      },
    },
    h2: {
      fontSize: '20px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '26px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: 0,
      },
    },
    h3: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '24px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '28px',
        lineHeight: '36px',
        letterSpacing: 0,
      },
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '22px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: 0,
      },
    },
    h5: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '18px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: 0,
      },
    },
    h6: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: fontFamily,
      lineHeight: '16px',
      letterSpacing: 0,
      '@media (min-width:576px)': {
        fontSize: '18px',
        lineHeight: '26px',
        letterSpacing: 0,
      },
    },
    body1: {
      fontSize: '18px',
      fontFamily: fontFamily,
      lineHeight: '28px',
      letterSpacing: 0,
    },
    'body1-bold': {
      fontSize: '18px',
      fontFamily: fontFamily,
      fontWeight: 'bold',
      lineHeight: '28px',
      letterSpacing: 0,
    },
    body2: {
      fontSize: '16px',
      fontFamily: fontFamily,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    'body2-bold': {
      fontSize: '16px',
      fontFamily: fontFamily,
      fontWeight: 'bold',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    body3: {
      fontSize: '15px',
      fontFamily: fontFamily,
      lineHeight: '22px',
      letterSpacing: 0,
    },
    'body3-bold': {
      fontSize: '14px',
      fontFamily: fontFamily,
      fontWeight: 'bold',
      lineHeight: '22px',
      letterSpacing: 0,
    },
    caption: {
      fontSize: '12px',
      fontFamily: fontFamily,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    'caption-bold': {
      fontSize: '12px',
      fontFamily: fontFamily,
      fontWeight: 'bold',
      lineHeight: '20px',
      letterSpacing: 0,
    },
  },
});

export default theme;
