import http from './http';

export type FMOptions<TVars = any> = {
  variables?: TVars;
  context: {
    path: string;
    method?: string;
    contentType?: string;
  };
};

/**
 * @function fetcherMutation
 * @param options
 */
const fetcherMutation = async (options: FMOptions) => {
  const { variables, context } = options;
  const { path = '', method, contentType } = context;
  const response = await http({
    path: path,
    body: variables,
    method: method ?? 'POST',
    headers: {
      'Content-Type': contentType ?? 'application/json',
    },
  });

  return Promise.resolve(response);
};

export default fetcherMutation;
