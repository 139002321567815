import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { animated } from 'react-spring';

import theme from '@/styles/material/theme';

export const NotificationOverlay = styled.div`
  position: fixed;
  z-index: 1300;
  display: flex;
  top: ${theme.spacing(4)};
  left: ${theme.spacing(4)};
  right: ${theme.spacing(4)};
  flex-direction: column;
  align-items: center;
  width: 0 auto;
`;

export const disabledCx = css`
  pointer-events: none;
  user-select: none;
`;

export const NotificationItemContainer = styled(animated.div)`
  position: relative;
  margin-bottom: ${theme.spacing(1)};
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
