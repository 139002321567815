import type { BaseResponse } from '@/model/types';
import { APIError } from '@/utils/react-query-utilities/error';

/**
 * @function getErrorMessage
 * @param error
 */
const getErrorMessage = (error: Error | APIError<BaseResponse>): string => {
  if (error instanceof APIError) {
    const { header } = error.payload;
    const message = header.messages.reduce((_, curr) => curr, '');
    return message || '';
  }
  return error.message ?? '';
};

export default getErrorMessage;
