import { useCallback } from 'react';

import type { BaseResponse } from '@/model/types';
import type { LogoutResponse } from '@/model/logout/types';
import type { AuthenticationEmitterType } from '@/context/authentication/model/events';
import type { APIError } from '@/utils/react-query-utilities/error';

import getErrorMessage from '@/utils/react-query-utilities/get-error-message';

interface Dependencies {
  emitter: AuthenticationEmitterType;
}

/**
 * @function useLogoutEvent
 */
const useLogoutEvent = (deps: Dependencies) => {
  const { emitter } = deps;

  /**
   * @function _onSuccess
   * @param data
   */
  const _onSuccess = useCallback((data: LogoutResponse) => {
    const { data: payload } = data;
    if (!payload || !payload.is_success) {
      return;
    }

    window.location.replace('/sign-in');
  }, []);

  /**
   * @function _onError
   * @param data
   */
  const _onError = useCallback(
    (error: APIError<BaseResponse>) => {
      const message = getErrorMessage(error) || 'Failed to logout';
      emitter.emit('@authentication/logout-error', message);
    },
    [emitter],
  );

  return {
    onSuccess: _onSuccess,
    onError: _onError,
  };
};

export default useLogoutEvent;
