import type { GetServerSidePropsContext } from 'next';

import queryStringify from '@/utils/url/queryStringify';
import { API_HOST } from '@/constants';

import { APIError } from './error';

interface HttpOptions {
  path: string;
  baseURL?: string;
  params?: string;
  method?: string;
  headers?: Record<string, unknown>;
  body?: Record<string, unknown>;
}

/**
 * @function http
 * @param {any} options
 * @param {any} context
 *
 */
const http = async (options: HttpOptions, context?: GetServerSidePropsContext) => {
  const { path, baseURL, params, method = 'GET', headers = {}, body } = options;
  let _http_body = undefined;
  let _params = undefined;

  switch (method) {
    case 'DELETE':
    case 'POST':
    case 'PUT':
      if (body && body instanceof FormData) {
        _http_body = body;
      } else {
        _http_body = body ? JSON.stringify(body) : '';
      }
      break;
    case 'GET':
      _params = body ? queryStringify(body) : params;
      break;
    default:
      break;
  }

  const base = baseURL || API_HOST;
  const endpoint = base + path;
  const referer = (context && context.req.headers.referer) ?? '';

  const response = await fetch(endpoint + (_params ? `?${_params}` : ''), {
    method: method,
    body: _http_body,
    headers: {
      Referer: referer,
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
  });

  const json = await response.json();
  if (!json && response.status >= 400 && response.status < 500) {
    throw new Error(`[${response.status}] ${response.statusText}`);
  } else if (response.status >= 400) {
    throw new APIError(`[${response.status}] ${response.statusText}`, json);
  }

  return json;
};

export default http;
